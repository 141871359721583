// eslint-disable-next-line no-console
console.log('                                                                      \n'
        + '                  *****                         *****                 \n'
        + '             ***************,             ,***************            \n'
        + '          *********************,       **********************         \n'
        + '        **************************   *************************        \n'
        + '       **************************** ****************************      \n'
        + '      ***********************************************************     \n'
        + '     ****        *************************************        ****    \n'
        + '    ***             *******************************             ***   \n'
        + '    *                ,***************************                 *   \n'
        + '                       *************************                      \n'
        + ' #%%%%                 *************************                 %%%%/\n'
        + ' %%%%%                  ***********************                  %%%%%\n'
        + '                        ***********************                       \n'
        + '                                                                      \n'
        + 'https://careers.fool.com/');
